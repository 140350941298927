import React from 'react';

export default function UserSidebar({ setActiveTab }) {
    return (
        <aside className="sidebar">
            <h2>Vezérlőpult</h2>
            <h3>Profil</h3>
            <nav>
                <ul>
                    <li onClick={() => setActiveTab('profile')}>Felhasználói adatok</li>
                    <li onClick={() => setActiveTab('settings')}>Beállítások</li>
                </ul>
            </nav>

            <h3>Számlázás</h3>
            <nav>
                <ul>
                    <li onClick={() => setActiveTab('UserBillingInfo')}>Számlázási adatok</li>
                    <li>Számlák</li>
                </ul>
            </nav>

            <h3>Űrlapok</h3>
            <nav>
                <ul>
                    <li>Űrlap-1</li>
                    <li>Űrlap-2</li>
                </ul>
            </nav>

            <h3>Kapcsolat</h3>
            <nav>
                <ul>
                    <li>Üzenetek</li>
                </ul>
            </nav>
        </aside>
    )
}

const prices = [
    {
        "category": "BEMER",
        "isActive": true,
        "name": "BEMER kezelés",
        "description": "1 alkalom",
        "price": 4000
    },
    {
        "category": "BEMER",
        "isActive": true,
        "name": "BEMER kezelés - 10",
        "description": "10 alkalom vásárlása esetén kezelésenként",
        "price": 3800
    },
    {
        "category": "BEMER",
        "isActive": true,
        "name": "BEMER kezelés - 20",
        "description": "20 alkalom vásárlása esetén kezelésenként",
        "price": 3700
    },
    {
        "category": "BEMER",
        "isActive": true,
        "name": "BEMER kezelés - 30",
        "description": "30 alkalom vásárlása esetén kezelésenként",
        "price": 3600
    },
    {
        "category": "Safe Laser",
        "isActive": true,
        "name": "Safe Laser kezelés",
        "description": "1 alkalom",
        "price": 4000
    },
    {
        "category": "Safe Laser",
        "isActive": true,
        "name": "Safe Laser kezelés - 10",
        "description": "10 alkalom vásárlása esetén kezelésenként",
        "price": 3800
    },
    {
        "category": "Safe Laser",
        "isActive": true,
        "name": "Safe Laser kezelés - 20",
        "description": "20 alkalom vásárlása esetén kezelésenként",
        "price": 3700
    },
    {
        "category": "Safe Laser",
        "isActive": true,
        "name": "Safe Laser kezelés - 30",
        "description": "30 alkalom vásárlása esetén kezelésenként",
        "price": 3600
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Alap pedikűr",
        "description": "Áztatás, bőrkeményedés eltávolítása, köröm igazítás, krémezés",
        "price": 7500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Speciális pedikűr",
        "description": "Áztatás, erős bőrkeményedés, megvastagodott köröm, sarokrepedés, diabétesz láb, vérzékenység, peeling, krémezés",
        "price": 8500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Mini pedikűr",
        "description": "Áztatás, köröm formázás, köröm kezelés",
        "price": 3500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Tyúkszem eltávolítás",
        "description": "Ár darabonként értendő",
        "price": 2500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Körömgomba kezelése",
        "description": "Ár ujjanként értendő",
        "price": 2500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Alap pedikűr + körömgomba kezelés",
        "description": "Plusz 2 000 Ft ujjanként",
        "price": 7500
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Japán körömápolás",
        "description": "",
        "price": 6000
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Gél-lakk eltávolítás",
        "description": "Lemosóval",
        "price": 2000
    },
    {
        "category": "Lábápolás és Pedikűr",
        "isActive": true,
        "name": "Baba pedikűr",
        "description": "0-6 éves korig",
        "price": 2500
    },
    {
        "category": "SPA Kezelések",
        "isActive": true,
        "name": "Vizes talpmasszázs",
        "description": "20 perc",
        "price": 4000
    },
    {
        "category": "SPA Kezelések",
        "isActive": true,
        "name": "Paraffin kezelés",
        "description": "Paraffin kezelés hatóanyagos krémmel, csak pedikűrrel kérhető, bőr és/vagy körömgomba esetén nem vehető igénybe! 25 perc",
        "price": 5000
    },
    {
        "category": "Diagnosztika - Állapotfelmérés",
        "isActive": true,
        "name": "Talp diagnosztika",
        "description": "Állapotfelmérés",
        "price": 28000
    },
    {
        "category": "Diagnosztika - Állapotfelmérés",
        "isActive": true,
        "name": "Arc diagnosztika",
        "description": "Állapotfelmérés",
        "price": 28000
    },
    {
        "category": "Diagnosztika - Állapotfelmérés",
        "isActive": true,
        "name": "Álatalános állapotfelmérés",
        "description": "Állapotfelmérés, megközelítőleg 60 perc",
        "price": 20000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Reflexológiai talpmasszázs",
        "description": "60 perc (5 alkalmas bérlet: 45.000)",
        "price": 10000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Reflexológiai talpmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Méregtelenítő talpmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Bathi thai olajos talpmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Talpcsakra masszázs",
        "description": "20 perc (5 alkalmas bérlet: 25.000)",
        "price": 5500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Reflexzónás arcmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Herbalance arcmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Softlézeres fiatalító arcmasszász ",
        "description": "50 perc (5 alkalmas bérlet: 77.500)",
        "price": 16000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Japán arcmasszász ",
        "description": "30 perc (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Szervzónás kézmasszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs",
        "description": "30 perc CLASSIC  (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs + köpöly igény szerint",
        "description": "60 perc (5 alkalmas bérlet: 55.000)",
        "price": 11500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs + köpöly igény szerint",
        "description": "90 perc (5 alkalmas bérlet: 75.000)",
        "price": 17000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Lávaköves masszász",
        "description": "60 perc (5 alkalmas bérlet: 65.000)",
        "price": 13500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Lávaköves masszász",
        "description": "90 perc (5 alkalmas bérlet: 95.000)",
        "price": 19500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Kötőszöveti-reflexzónás mézmasszász",
        "description": "30 perc (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Kötőszöveti-reflexzónás mézmasszász",
        "description": "60 perc (5 alkalmas bérlet: 65.000)",
        "price": 13500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Tibeti méregtelenítő mézes masszász",
        "description": "30 perc (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Tibeti méregtelenítő mézes masszász",
        "description": "60 perc (5 alkalmas bérlet: 65.000)",
        "price": 13500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Kismama masszázs",
        "description": "30 perc (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Thermo masszázs",
        "description": "30 perc (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Thermo masszázs - vibrációs labdával",
        "description": "30 perc, VIP (5 alkalmas bérlet: 45.000)",
        "price": 10000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Trigger pont terápia",
        "description": "30 perc, egy adott terület fájdalom csillapítása  (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs, trigger pont terápia",
        "description": "60 perc  (5 alkalmas bérlet: 57.500)",
        "price": 12000
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs, trigger pont terápia",
        "description": "90 perc (5 alkalmas bérlet: 90.000)",
        "price": 18500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs, trigger pont terápia, köpöly + IMT Kor. tech.",
        "description": "60 perc  (5 alkalmas bérlet: 65.000)",
        "price": 13500
    },
    {
        "category": "Masszázsok",
        "isActive": true,
        "name": "Svéd masszázs, trigger pont terápia, köpöly + IMT Kor. tech.",
        "description": "90 perc TOP  (5 alkalmas bérlet: 95.000)",
        "price": 19500
    },
    {
        "category": "Baba Masszázs",
        "isActive": true,
        "name": "Baba masszázs - Nyugati olajos",
        "description": "0-3 éves kori, 30 perc (5 alkalmas bérlet: 25.000)",
        "price": 5500
    },
    {
        "category": "Baba Masszázs",
        "isActive": true,
        "name": "Baba masszázs - Thai",
        "description": "0-3 éves kori, 30 perc (5 alkalmas bérlet: 25.000)",
        "price": 5500
    },
    {
        "category": "Gyermek Masszázs",
        "isActive": true,
        "name": "Reflexológiai talpmasszázs",
        "description": "3-14 éves kori, 30 perc (5 alkalmas bérlet: 25.000)",
        "price": 5500
    },
    {
        "category": "Gyermek Masszázs",
        "isActive": true,
        "name": "Svéd masszázs",
        "description": "3-14 éves kori, 30 perc (5 alkalmas bérlet: 25.000)",
        "price": 5500
    },
    {
        "category": "Gyermek Masszázs",
        "isActive": true,
        "name": "Svéd masszázs",
        "description": "3-14 éves kori, 60 perc (5 alkalmas bérlet: 45.000)",
        "price": 9500
    },
    {
        "category": "Tekercselés",
        "isActive": true,
        "name": "Fóliás",
        "description": "5 alkalom felett 10% kedvezmény!",
        "price": 10000
    },
    {
        "category": "Tekercselés",
        "isActive": true,
        "name": "Vizes",
        "description": "5 alkalom felett 10% kedvezmény!",
        "price": 14000
    },
    {
        "category": "Tekercselés",
        "isActive": true,
        "name": "Köpöly",
        "description": "30 perc",
        "price": 3000
    },
    {
        "category": "Tekercselés",
        "isActive": true,
        "name": "Masszázs (alak formálást elősegítő krémekkel, olajokka, zselékkel egyénhez igazítva)",
        "description": "30 perc, tekercselés nélkül is kérhető (5 alkalmas bérlet: 30.000)",
        "price": 6500
    },
    {
        "category": "Tekercselés",
        "isActive": true,
        "name": "Masszázs (alak formálást elősegítő krémekkel, olajokka, zselékkel egyénhez igazítva)",
        "description": "45 perc, tekercselés nélkül is kérhető (5 alkalmas bérlet: 37.500)",
        "price": 8000
    },
    {
        "category": "Felnőtt Kinesio Tape",
        "isActive": true,
        "name": "Kinesio Tape - ragasztás felnőtteknek",
        "description": "Kis felületen / testtájanként",
        "price": 5000
    },
    {
        "category": "Felnőtt Kinesio Tape",
        "isActive": true,
        "name": "Kinesio Tape - ragasztás felnőtteknek",
        "description": "Nagy felületen / testtájanként",
        "price": 8000
    },
    {
        "category": "Gyermek Kinesio Tape",
        "isActive": true,
        "name": "Kinesio Tape - ragasztás gyermekeknek 14 éves korig",
        "description": "Kis felületen / testtájanként",
        "price": 4000
    },
    {
        "category": "Gyermek Kinesio Tape",
        "isActive": true,
        "name": "Kinesio Tape - ragasztás gyermekeknek 14 éves korig",
        "description": "Nagy felületen / testtájanként",
        "price": 6000
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 1",
        "description": "Vizes talpmasszázs + méregtelenítő talpmasszázs (50 perc) (5 alkalmas bérlet: 40.000)",
        "price": 8500
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 2",
        "description": "Vizes talpmasszázs + talpcsakra masszázs (40 perc) (5 alkalmas bérlet: 35.000)",
        "price": 7500
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 3",
        "description": "Vizes talpmasszázs + reflexzónás arcmasszázs (30 perc) (5 alkalmas bérlet: 40.000)",
        "price": 8500
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 4",
        "description": "Vizes talpmasszázs + méregtelenítő talpmasszázs + arcmasszázs (60 perc) (5 alkalmas bérlet: 72.000)",
        "price": 15000
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 5",
        "description": "Vizes talpmasszázs + talpcsakra masszázs + arcmasszázs (50 perc) (5 alkalmas bérlet: 70.000)",
        "price": 14500
    },
    {
        "category": "Masszázs Csomagok",
        "isActive": true,
        "name": "Masszázs Csomag 6",
        "description": "Softlézeres fiatalító arcmasszász + testmasszázs (90 perc) TOP (5 alkalmas bérlet: 120.000)",
        "price": 25000
    },
    {
        "category": "Pedi és Masszázs Csomagok",
        "isActive": true,
        "name": "Csomag 1",
        "description": "Alap pedikűr + talpmasszázs + paraffinos kezelés ható anyagos krémmel (1,5-2 óra egyéntől függően)",
        "price": 17000
    },
    {
        "category": "Pedi és Masszázs Csomagok",
        "isActive": true,
        "name": "Csomag 2",
        "description": "Alap pedikűr + japán körömápolás + vizes masszázs (1,5-2 óra egyéntől függően)",
        "price": 15500
    },
    {
        "category": "Extra Pedi és Masszázs csomagok",
        "isActive": true,
        "name": "Manager csomag férfiaknak",
        "description": "Alap pedikűr + japán körömápolás +  talpmasszázs + paraffinos kezelés hatóanyagos krémmel (2-2,5 óra egyéntől függően)",
        "price": 22000
    },
    {
        "category": "Extra Pedi és Masszázs csomagok",
        "isActive": true,
        "name": "Kényeztető csomag nőknek",
        "description": "Alap pedikűr + japán körömápolás +  arcmasszázs + paraffinos kezelés hatóanyagos krémekkel vagy olajokkal (2-2,5 óra egyéntől függően)",
        "price": 22000
    },
    {
        "category": "Relax idő",
        "isActive": true,
        "name": "Pihenő idő",
        "description": "Szolgáltatáshoz kérhető alvás idő (30 perc) (5 alkalmas bérlet: 12.500)",
        "price": 3000
    },
    {
        "category": "Relax idő",
        "isActive": true,
        "name": "Pihenő idő",
        "description": "Szolgáltatáshoz kérhető alvás idő (60 perc) (5 alkalmas bérlet: 27.500)",
        "price": 6000
    },
    {
        "category": "Inactive test",
        "isActive": false,
        "name": "Test inactive service",
        "description": "This is a test service to test the filter",
        "price": 5000
    }
];

export default prices;
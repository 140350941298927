import React, { useState, useEffect } from 'react';

import { MdContentCopy as CopyIcon } from "react-icons/md";

import admin from './js/db_admin';

export default function Profile() {

    useEffect(() => {
        return;
        const fetchAdmin = async () => {
            try {
                return;
            } catch (error) {
                return;
            }
        };
    }, []);

    const copyCode = () => {
        const textToCopy = admin.adataim.azonosito.toLocaleUpperCase();
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert(`Azonosító vágolapra másolva: ${textToCopy}`);
            })
            .catch(err => {
                alert("Failed to copy text: ", err);
            });
    };

    return (
        <>
            <div className='breadcrumbs'>Profil / Adataim</div>

            <section className="card">
                <h2>Azonosító</h2>

                <form action="">

                    <div className="form-group">
                        <div className='admin-code'>
                            {admin.adataim.azonosito.split('').map((char, index) => (
                                <span key={index}>{char}</span>
                            ))}
                            <CopyIcon title='Vágólapra másol' className='icon' onClick={copyCode} />
                        </div>
                    </div>
                </form>
            </section>

            <section className="card ">
                <h2>Név</h2>

                <form action="">

                    <div className="form-group">
                        <label>Név</label>
                        <span>{admin.adataim.nev}</span>
                    </div>

                    <div className="form-group">
                        <label>Új vezetéknév</label>
                        <input type="text" />
                    </div>

                    <div className="form-group">
                        <label>Új utónév</label>
                        <input type="text" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Email cím</h2>

                <form action="">
                    <div className="form-group">
                        <label>Email cím</label>
                        <span>{admin.adataim.email}</span>
                    </div>

                    <div className="form-group">
                        <label>Új email cím *</label>
                        <input type="email" />
                    </div>

                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Telefonszám</h2>

                <form action="">
                    <div className="form-group">
                        <label>Telefonszám</label>
                        <span>{admin.adataim.tel}</span>
                    </div>

                    <div className="form-group">
                        <label>Új telefonszám *</label>
                        <input type="tel" />
                    </div>

                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Számlázási adatok</h2>

                <form action="">
                    <div className="form-group">
                        <label>Számlázási név</label>
                        <span>{admin.adataim.szamla_nev}</span>
                    </div>
                    <div className="form-group">
                        <label>Számlázási cím</label>
                        <span>{admin.adataim.szamla_cim}</span>
                    </div>
                    <div className="form-group">
                        <label>Új számlázási név *</label>
                        <input type="text" />
                    </div>
                    <div className="form-group">
                        <label>Új számlázási cím *</label>
                        <input type="text" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Jelszó</h2>

                <form action="">
                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <div className="form-group">
                        <label>Új jelszó *</label>
                        <input type="password" />
                    </div>

                    <div className="form-group">
                        <label>Új jelszó megerősítése *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>
        </>
    );
}

import React, { useState } from 'react';
import { FaSave as SaveIcon, FaPrint as PrintIcon, FaEye as PreviewIcon } from "react-icons/fa";
import bills from './js/db_bills';
import prices from './js/db_prices';
import customers from './js/db_customers';

export default function NewBill() {
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [email, setEmail] = useState("");
    const [selectedService, setSelectedService] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [selectedServices, setSelectedServices] = useState([]);
    const [sessionDate, setSessionDate] = useState(new Date().toISOString().split("T")[0]);
    const [dueDate, setDueDate] = useState(new Date().toISOString().split("T")[0]);
    const [isPaid, setIsPaid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const generateBillNumber = () => `TA-${Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;

    const addService = () => {
        if (!selectedService) return;

        const service = prices.find(s => s.name === selectedService);
        if (service) {
            const newService = {
                ...service,
                quantity: parseInt(quantity)
            };
            setSelectedServices([...selectedServices, newService]);
        }

        setSelectedService("");
        setQuantity(1);
    };

    const removeService = (serviceName) => {
        setSelectedServices(selectedServices.filter(service => service.name !== serviceName));
    };

    const calculateTotalAmount = () => {
        return selectedServices.reduce((acc, service) => acc + (service.price * service.quantity), 0);
    };

    const totalAmount = calculateTotalAmount();

    const saveBill = () => {
        if (customerName === "" || email === "" || selectedServices.length === 0) {
            alert("Kérjük, töltse ki az összes mezőt és válasszon szolgáltatást!");
            return;
        }

        const newBill = {
            isPaid,
            customerName,
            email,
            amount: totalAmount,
            billNumber: generateBillNumber(),
            dueDate,
            services: selectedServices.map(service => `${service.name} (x${service.quantity})`),
            sessionDate
        };

        bills.push(newBill);
        alert("Számla sikeresen mentve!");

        setSelectedCustomer("");
        setCustomerName("");
        setEmail("");
        setSelectedServices([]);
        setSessionDate(new Date().toISOString().split("T")[0]);
        setDueDate(new Date().toISOString().split("T")[0]);
        setIsPaid(false);
    };

    const printModalContent = () => {
        const modalContent = document.getElementById("modal-content");
        const newWindow = window.open("", "_blank");
        newWindow.document.write(modalContent.innerHTML);
        newWindow.print();
        newWindow.close();
    };

    const handleCustomerChange = (e) => {
        const selected = e.target.value;
        setSelectedCustomer(selected);

        if (selected === "manual") {
            setCustomerName("");
            setEmail("");
        } else {
            const customer = customers.find(c => c.name === selected);
            if (customer) {
                setCustomerName(customer.name);
                setEmail(customer.email);
            }
        }
    };

    return (
        <section className="card new-bill-card">
            <h2>Új számla létrehozása</h2>

            {/* Customer Selection */}
            <div className="form-group">
                <label>Vevő kiválasztása:</label>
                <select value={selectedCustomer} onChange={handleCustomerChange}>
                    <option value="">-- Válasszon vevőt --</option>
                    {customers.map((customer, index) => (
                        <option key={index} value={customer.name}>{customer.name}</option>
                    ))}
                    <option value="manual">Másik vevő (kézi bevitel)</option>
                </select>
            </div>

            {selectedCustomer === "manual" && (
                <div className="form-group">
                    <label>Név:</label>
                    <input
                        type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        placeholder="Vevő neve"
                    />
                </div>
            )}

            <div className="form-group">
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email cím"
                />
            </div>

            <div className="form-group">
                <label>Szolgáltatás kiválasztása:</label>
                <select value={selectedService} onChange={(e) => setSelectedService(e.target.value)}>
                    <option value="">-- Válasszon szolgáltatást --</option>
                    {prices.filter(service => service.isActive).map((service, index) => (
                        <option key={index} value={service.name}>{service.name} - {service.price} Ft</option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label>Mennyiség:</label>
                <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    min="1"
                />
            </div>
            <button className="btn add-service-btn" onClick={addService}>Szolgáltatás Hozzáadása</button>

            {/* List of Selected Services */}
            <div className="selected-services">
                <h3>Kiválasztott szolgáltatások:</h3>
                {selectedServices.map((service, index) => (
                    <div key={index} className="selected-service-item">
                        <span>{service.name} - {service.price} Ft x {service.quantity} = {service.price * service.quantity} Ft</span>
                        <button onClick={() => removeService(service.name)}>&times;</button>
                    </div>
                ))}
                <h3>Összesen: {totalAmount} Ft</h3>
            </div>

            <div className="form-group">
                <label>Fizetett:</label>
                <input type="checkbox" checked={isPaid} onChange={() => setIsPaid(!isPaid)} />
            </div>

            <div className="button-container">
                <button className="btn save-btn" onClick={saveBill}>
                    <SaveIcon className='icon' /> Mentése
                </button>
                <button className="btn preview-btn" onClick={() => setIsModalOpen(true)}>
                    <PreviewIcon className='icon' /> Előnézet
                </button>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div id="modal-content" className="modal-content">
                        <div className="modal-header">
                            <h2>Számla előnézet</h2>
                            <button className="btn close-btn" onClick={() => setIsModalOpen(false)}>Bezárás</button>
                        </div>
                        <div className="modal-body">
                            <p><strong>Vevő:</strong> {customerName}</p>
                            <p><strong>Email:</strong> {email}</p>
                            <p><strong>Dátum:</strong> {sessionDate}</p>
                            <p><strong>Határidő:</strong> {dueDate}</p>

                            <h3>Szolgáltatások:</h3>
                            <ul className="services-list">
                                {selectedServices.map((service, index) => (
                                    <li key={index}>
                                        <span>{service.name}</span>
                                        <span>{service.price} Ft x {service.quantity} = {service.price * service.quantity} Ft</span>
                                    </li>
                                ))}
                            </ul>

                            <div className="total-amount">Összesen: {totalAmount} Ft</div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn print-btn" onClick={printModalContent}>
                                <PrintIcon className='icon' /> Nyomtatás
                            </button>
                            <button className="btn close-btn" onClick={() => setIsModalOpen(false)}>Bezárás</button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

const bills = [
    { 
        isPaid: true, 
        customerName: "Kovács János", 
        email: "janos@example.com", 
        amount: 120, 
        billNumber: "001", 
        dueDate: "2024-11-10", 
        services: ["Svéd masszázs", "Aromaterápia"], 
        sessionDate: "2024-11-08"
    },
    { 
        isPaid: false, 
        customerName: "Nagy Anna", 
        email: "anna@example.com", 
        amount: 180, 
        billNumber: "002", 
        dueDate: "2024-11-18", 
        services: ["Mélyszöveti masszázs", "Forró köves terápia"], 
        sessionDate: "2024-11-15"
    },
    { 
        isPaid: true, 
        customerName: "Tóth Aliz", 
        email: "aliz@example.com", 
        amount: 250, 
        billNumber: "003", 
        dueDate: "2024-11-12", 
        services: ["Thai masszázs", "Reflexológia", "Sport masszázs"], 
        sessionDate: "2024-11-10"
    },
    { 
        isPaid: false, 
        customerName: "Kiss Mihály", 
        email: "mihaly@example.com", 
        amount: 100, 
        billNumber: "004", 
        dueDate: "2024-11-20", 
        services: ["Svéd masszázs"], 
        sessionDate: "2024-11-18"
    },
    { 
        isPaid: true, 
        customerName: "Németh Emese", 
        email: "emese@example.com", 
        amount: 150, 
        billNumber: "005", 
        dueDate: "2024-11-15", 
        services: ["Forró köves terápia"], 
        sessionDate: "2024-11-14"
    },
    // Additional 10 bills
    { 
        isPaid: true, 
        customerName: "Farkas László", 
        email: "laszlo@example.com", 
        amount: 200, 
        billNumber: "006", 
        dueDate: "2024-12-01", 
        services: ["Aromaterápia", "Sport masszázs"], 
        sessionDate: "2024-11-29"
    },
    { 
        isPaid: false, 
        customerName: "Szabó Zsófia", 
        email: "zsofia@example.com", 
        amount: 170, 
        billNumber: "007", 
        dueDate: "2024-12-05", 
        services: ["Reflexológia", "Svéd masszázs"], 
        sessionDate: "2024-12-03"
    },
    { 
        isPaid: true, 
        customerName: "Horváth Péter", 
        email: "peter@example.com", 
        amount: 130, 
        billNumber: "008", 
        dueDate: "2024-11-25", 
        services: ["Thai masszázs"], 
        sessionDate: "2024-11-23"
    },
    { 
        isPaid: false, 
        customerName: "Molnár Tamás", 
        email: "tamas@example.com", 
        amount: 220, 
        billNumber: "009", 
        dueDate: "2024-12-10", 
        services: ["Sport masszázs", "Forró köves terápia"], 
        sessionDate: "2024-12-08"
    },
    { 
        isPaid: true, 
        customerName: "Papp Júlia", 
        email: "julia@example.com", 
        amount: 90, 
        billNumber: "010", 
        dueDate: "2024-12-15", 
        services: ["Aromaterápia"], 
        sessionDate: "2024-12-13"
    },
    { 
        isPaid: false, 
        customerName: "Varga Dániel", 
        email: "daniel@example.com", 
        amount: 260, 
        billNumber: "011", 
        dueDate: "2024-11-22", 
        services: ["Mélyszöveti masszázs", "Reflexológia"], 
        sessionDate: "2024-11-20"
    },
    { 
        isPaid: true, 
        customerName: "Bíró Noémi", 
        email: "noemi@example.com", 
        amount: 300, 
        billNumber: "012", 
        dueDate: "2024-12-20", 
        services: ["Forró köves terápia", "Sport masszázs"], 
        sessionDate: "2024-12-18"
    },
    { 
        isPaid: false, 
        customerName: "Kelemen Ádám", 
        email: "adam@example.com", 
        amount: 185, 
        billNumber: "013", 
        dueDate: "2024-12-08", 
        services: ["Svéd masszázs"], 
        sessionDate: "2024-12-06"
    },
    { 
        isPaid: true, 
        customerName: "Takács Erika", 
        email: "erika@example.com", 
        amount: 140, 
        billNumber: "014", 
        dueDate: "2024-12-12", 
        services: ["Aromaterápia", "Reflexológia"], 
        sessionDate: "2024-12-10"
    },
    { 
        isPaid: false, 
        customerName: "Vörös András", 
        email: "andras@example.com", 
        amount: 110, 
        billNumber: "015", 
        dueDate: "2024-11-28", 
        services: ["Thai masszázs"], 
        sessionDate: "2024-11-26"
    }
];

export default bills;

import React, { useState, useEffect } from 'react';

import { FiRefreshCw as ResetIcon } from "react-icons/fi";
import { FaFileExport as ExpIcon } from "react-icons/fa";
import { MdEdit as EditIcon } from "react-icons/md";
import { MdDelete as DeleteIcon } from "react-icons/md";

import subscribers from './js/db_subscribers';

export default function Subscribers() {

    const filteredClients = subscribers;

    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Feliratkozók kezelése</div>

            <section className="card admin-card">
                <h2>Új feliratkozó hozzáadása</h2>

                <form>
                    <div className="form-group">
                        <label htmlFor="client-name">Név</label>
                        <input type="text" id="client-name" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="client-name">Email</label>
                        <input type="text" id="client-name" required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="client-name">Csoportok</label>
                        <select>
                            <option value="">Összes csoport</option>
                        </select>
                    </div>

                    <button type="submit" className='btn save-btn'>Feliratkozó hozzáadása</button>
                </form>
            </section>


            <section className="card admin-card">
                <h2>Feliratkozók listája</h2>

                <div className="service-filters">
                    <input
                        type="text"
                        placeholder="Keresés email alapján..."
                    />

                    <input
                        type="text"
                        placeholder="Keresés név alapján..."
                    />

                    <select>
                        <option value="">Összes csoport</option>
                    </select>
                </div>

                <div className="button-container">
                    <button className="btn save-btn">
                        <ResetIcon className='icon' />
                        Szűrők nullázása
                    </button>

                    <button className="btn save-btn">
                        <ExpIcon className='icon' />
                        Export Excel-be
                    </button>
                </div>

                <div className="service-list">
                    <table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Név</th>
                                <th>Csoportok</th>
                                <th>Műveletek</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClients.length > 0 ? (
                                filteredClients.map((client, index) => (
                                    <tr key={index}>
                                        <td>{client.email}</td>
                                        <td>{client.name}</td>
                                        <td>{client.groups.join(", ")}</td>

                                        <td className='action-cell'>
                                            <button className="btn edit-btn">
                                                <EditIcon className='icon' />
                                            </button>
                                            <button className="btn delete-btn">
                                                <DeleteIcon className='icon' />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>

        </>
    );
}

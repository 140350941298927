import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default function AdminLogin() {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/admin/vezerlopult');
    };

    return (
        <HelmetProvider>
            <div className='page log-in-out'>
                <Helmet>
                    <title>Admin Bejelentkezés</title>
                </Helmet>

                <div className="panel">
                    <h2>Admin Bejelentkezés</h2>

                    <form onSubmit={handleSubmit} method="post">
                        <div className="form-group">
                            <label htmlFor="email">Email cím vagy telefonszám:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Írja be az email címét vagy telefonszámát"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Jelszó:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Írja be a jelszavát"
                            />
                        </div>

                        <div className="form-footer">
                            <button className='btn login-btn'>Bejelentkezés</button>
                            <p>
                                Nincs még fiókja?{' '}
                                <Link to="/admin/regisztracio">
                                    Regisztráljon itt
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </HelmetProvider>
    );
}

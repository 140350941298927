import React from 'react';
import logo from '../img/terapp_logo.png';
import logo_white_orange from '../img/terapp_logo_white_orange.png';

export default function Home() {
    return (
        <div style={styles.container}>
            {/* Navigation Bar */}
            <nav style={styles.navbar}>
                <img src={logo_white_orange} alt="TerApp Logo" style={styles.navLogo} />
                <div style={styles.navLinks}>
                    <a href="#" style={styles.link}>Home</a>
                    <a href="#" style={styles.link}>Features</a>
                    <a href="#" style={styles.link}>About</a>
                    <a href="#" style={styles.link}>Contact</a>
                </div>
            </nav>

            {/* Main Content */}
            <div style={styles.content}>
                {/* Login Button */}
                <button style={styles.loginButton}>Login</button>

                <img src={logo} alt="TerApp Logo" style={styles.logo} />
                <h1 style={styles.heading}>Welcome to TerApp</h1>
                <p style={styles.description}>
                    Your go-to platform for managing documents efficiently and securely.
                </p>
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                <p style={styles.footerText}>
                    &copy; {new Date().getFullYear()} TerApp. All rights reserved.
                </p>
                <div style={styles.socialLinks}>
                    <a href="#" style={styles.socialLink}>Facebook</a>
                    <a href="#" style={styles.socialLink}>Twitter</a>
                    <a href="#" style={styles.socialLink}>LinkedIn</a>
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
    },
    navbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        backgroundColor: '#1E4A8C',
        color: '#fff',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    navLogo: {
        width: '50px',
    },
    navLinks: {
        display: 'flex',
        gap: '20px',
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: '1rem',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '20px',
    },
    loginButton: {
        backgroundColor: '#1E4A8C',
        color: '#fff',
        border: 'none',
        borderRadius: '25px',
        padding: '10px 20px',
        fontSize: '1rem',
        cursor: 'pointer',
        marginBottom: '20px',
        transition: 'background-color 0.3s',
    },
    logo: {
        width: '250px',
        marginBottom: '20px',
    },
    heading: {
        fontSize: '2rem',
        color: '#333',
    },
    description: {
        fontSize: '1.2rem',
        color: '#666',
        maxWidth: '600px',
        margin: '0 auto',
    },
    footer: {
        backgroundColor: '#1E4A8C',
        color: '#fff',
        padding: '20px',
        textAlign: 'center',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
    },
    footerText: {
        marginBottom: '10px',
    },
    socialLinks: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'center',
    },
    socialLink: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: '1rem',
        transition: 'color 0.3s',
    },
};

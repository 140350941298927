import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IoIosArrowDown as OpenIcon } from "react-icons/io";
import { Link } from 'react-router-dom';

import menu from './admin/js/menu';

export default function SidebarSection({ sectionIndex, activeMenu, setActiveMenu}) {

    const [isSectionOpen, setIsSectionOpen] = useState(menu[sectionIndex].isOpenByDefault);
    const [navHeight, setNavHeight] = useState(0);

    // Using a ref to access the nav element
    const navRef = useRef(null);

    const openMenu = () => {
        setIsSectionOpen(!isSectionOpen);
    };

    // Calculate nav height whenever the section is opened/closed
    useEffect(() => {
        if (navRef.current) {
            setNavHeight(navRef.current.scrollHeight);
        }
    }, [isSectionOpen]);

    const location = useLocation();

  useEffect(() => {
    // This will log the full current URL path whenever the component renders
    setActiveMenu((location.pathname + location.search).split('/').at(-1));
  }, [location]);

   return (
        <div className='menu-section'>
            <div className='menu-head' onClick={openMenu}>
                <h3>{menu[sectionIndex].name}</h3>
                <OpenIcon className={isSectionOpen ? 'icon point-up' : 'icon'} />
            </div>
            <nav
                ref={navRef}
                id={menu[sectionIndex].id}
                style={{ marginTop: isSectionOpen ? '0px' : `-${navHeight}px` }}
            >
                <ul>
                    {menu[sectionIndex].items.map((item, index) => (
                        <li key={index} >
                            <Link
                                className={item.path.split('/').at(-1) === activeMenu ? 'active' : ''}
                                to={item.path}
                                onClick={() => setActiveMenu(item.itemName)}
                            >
                                {item.itemName}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

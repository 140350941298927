import ArchiveBills from "../ArchiveBills";
import ArchiveNewsletters from "../ArchiveNewsletters";
import Servicesrs from "../Services";
import Subscribers from "../Subscribers";
import NewBill from "../NewBill";
import Profile from "../Profile";
import SendNewsletter from "../SendNewsletter";
import Settings from "../Settings";

const menu = [
    {
        name: 'Profil',
        isOpenByDefault: true,
        id: 'admin-profile-nav',
        items: [
            { itemName: 'Adataim', path: '/admin/vezerlopult', element: <Profile />, },
            { itemName: 'Beállítások', path: '/admin/beallitasok', element: <Settings />, }
        ],
        isActive: true,
    },
    {
        name: 'Hírlevél',
        isOpenByDefault: true,
        id: 'admin-newsletter-nav',
        items: [
            { itemName: 'Küldés', path: '/admin/hirlevel/kuldes', element: <SendNewsletter />, },
            { itemName: 'Feliratkozók', path: '/admin/hirlevel/feliratkozok', element: <Subscribers />, },
            { itemName: 'Arhívum', path: '/admin/hirlevel/arhivum', element: <ArchiveNewsletters />, }
        ],
        isActive: true,
    },
    {
        name: 'Szolgáltatások',
        isOpenByDefault: true,
        id: 'admin-settings-nav',
        items: [
            { itemName: 'Árak', path: '/admin/szolgaltatasok/arak', element: <Servicesrs />, }
        ],
        isActive: true,
    },
    {
        name: 'Felhasználók',
        isOpenByDefault: true,
        id: 'admin-users-nav',
        items: [
            { itemName: 'Ürlapok', path: '/admin/urlapok', element: '', },
            { itemName: 'Fájlok', path: '/admin/fajlok', element: '', },
            { itemName: 'Bérletek', path: '/admin/berletek', element: '', },
            { itemName: 'Számlák', path: '/admin/szamlak', element: '', },
            { itemName: 'Üzenetek', path: '/admin/uzenetek', element: '', }
        ],
        isActive: false,
    },
    {
        name: 'Számlák',
        isOpenByDefault: true,
        id: 'admin-billing-nav',
        items: [
            { itemName: 'Új számla', path: '/admin/szamlak/uj', element: <NewBill />, },
            { itemName: 'Arhívum', path: '/admin/szamlak/szamla-arhivum', element: <ArchiveBills />, }
        ],
        isActive: true,
    }
];

export default menu;

import React from 'react';

export default function ArchiveNewsletters() {
    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Arhívum</div>

            <section className="card ">
                <h2>Hírlevél arhívum</h2>

                <div>Itt fogod látni a már elküldött hírlevleket.</div>
            </section>
        </>
    )
}

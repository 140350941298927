import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './styles/App.css';
import UserRegistration from './components/user/UserRegistration ';
import UserDashboard from './components/user/user-dashboard/UserDashboard';

import AdminLogin from './components/admin/AdminLogin';
import AdminRegistration from './components/admin/AdminRegistration ';
import Dashboard from './components/admin/Dashboard';
import Home from './components/Home';

import menu from './components/admin/js/menu';

export default function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='*' element={<h1>Oldal nem található</h1>} />

          <Route path='/' element={<Home />} />

          {menu.map(menu => menu.items.map((item, index) => (
            menu.isActive && <Route key={index} path={item.path} element={<Dashboard content={item.element} />} />
          )))}

          <Route path='/regisztracio' element={<UserRegistration />} />
          <Route path='/vezerlopult' element={<UserDashboard />} />
          <Route path='/admin/bejelentkezes' element={<AdminLogin />} />
          <Route path='/admin/regisztracio' element={<AdminRegistration />} />
        </Routes>
      </div>
    </Router>
  )
}

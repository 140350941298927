import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import menu from './js/menu';

export default function Dashboard({ content }) {
    const [breadcrumbs, setBreadcrumbs] = useState('Profil / Adataim');

    const greetAdmin = () => {
        const time = new Date().getHours();
        if (time >= 5 && time <= 8) return 'Jó reggelt';
        if (time > 8 && time <= 18) return 'Üdvözöljük';
        if (time > 18 && time <= 23) return 'Jó estét';
        return 'Üdvözöljük';
    };

    const getBreadcrumbs = () => {
        return;
    };

    return (
        <div className="dashboard">
            <Sidebar />

            <main className="content">
                <header>
                    <h1>{greetAdmin()} Anett!</h1>
                    <Link to="/admin/bejelentkezes" className="btn logout-btn">Kijelentkezés</Link>
                </header>

                {/*<div className='breadcrumbs'>{breadcrumbs}</div>*/}

                {content}
            </main>
        </div>
    );
}

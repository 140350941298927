import React, { useState, useEffect } from 'react';
import subscribers from './js/db_subscribers';
import TextEditor from './Texteditor';

import { PiMicrosoftOutlookLogoFill as OutlookIcon } from "react-icons/pi";
import { LuSendHorizonal as SendIcon } from "react-icons/lu";

export default function SendNewsletter() {
    const [mailTo, setMailTo] = useState('');

    useEffect(() => {
        const mailtoList = subscribers.map(item => item.email);
        setMailTo(mailtoList.join(","));
    }, []);

    const subject = encodeURIComponent('Hatalmas akció minden BEMER termékre!');
    const body = encodeURIComponent(`Kedves Vendégünk!
        
        Örömmel értesítünk, hogy elindult a nagy BEMER akció, ahol most minden termékünket kedvezményes áron szerezheted be!
        
        🎁 Akciós ajánlatok, amiket nem érdemes kihagyni:
        
        - BEMER Classic és Pro készülékek most rendkívüli kedvezményekkel!
        - Speciális kiegészítők, hogy még hatékonyabban támogasd egészséged.
        - Exkluzív ajándékok minden rendelés mellé.
        
        Látogass el weboldalunkra és rendelj most!
        
        Üdvözlettel,
        A BEMER csapata
    `);

    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Küldés</div>
            <section className="card">
                <h2>Hírlevél saját eszközről</h2>
                <div>
                    <a
                        className='btn save-btn'
                        href={`mailto:${mailTo}?subject=${subject}&body=${body}`}
                    >
                        <OutlookIcon className='icon' />
                        Email mindenkinek
                    </a>
                </div>
            </section>

            <section className="card">
                <h2>Webmail</h2>

                <TextEditor />

                <div>
                    <a
                        className='btn save-btn'
                    >
                        <SendIcon className='icon' />
                        Küldés
                    </a>
                </div>

            </section>
        </>
    );
}

import React, { useState, useEffect } from 'react';

export default function UserProfile() {

    const [firstName, setFirstName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [dob, setDob] = useState(null);
    const [gender, setGender] = useState(null);

    useEffect(() => {
        return;
        const fetchProfile = async () => {
            try {
                return;
            } catch (error) {
                return;
            }
        };
    }, []);

    return (
        <>
            <section className="card ">
                <h2>Alap adatok</h2>

                <form action="">
                    <div className="form-group">
                        <label>Vezetéknév</label>
                        <input type="text" placeholder="Adja meg vezetéknevét" defaultValue="Károly" />
                    </div>

                    <div className="form-group">
                        <label>Utónév</label>
                        <input type="text" placeholder="Adja meg utónevét" />
                    </div>

                    <div className="form-group">
                        <label>Születési dátum</label>
                        <input type="date" />
                    </div>

                    <div className="form-group">
                        <label>Nem</label>
                        <select>
                            <option>Férfi</option>
                            <option>Nő</option>
                            <option>Egyéb</option>
                        </select>
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Email cím</h2>

                <form action="">
                    <div className="form-group">
                        <label>Email cím</label>
                        <span>karoly.webdev@gmail.com</span>
                    </div>

                    <div className="form-group">
                        <label>Új email cím *</label>
                        <input type="email" />
                    </div>

                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Telefonszám</h2>

                <form action="">
                    <div className="form-group">
                        <label>Telefonszám</label>
                        <span>+36201234567</span>
                    </div>

                    <div className="form-group">
                        <label>Új telefonszám *</label>
                        <input type="tel" />
                    </div>

                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Lakcím</h2>

                <form action="">
                    <div className="form-group">
                        <label>Lakcím</label>
                        <span>2550 Dabas, Hold utca 49.</span>
                    </div>

                    <div className="form-group">
                        <label>Új lakcím *</label>
                        <input type="text" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Jelszó</h2>

                <form action="">
                    <div className="form-group">
                        <label>Jelszó *</label>
                        <input type="password" />
                    </div>

                    <div className="form-group">
                        <label>Új jelszó *</label>
                        <input type="password" />
                    </div>

                    <div className="form-group">
                        <label>Új jelszó megerősítése *</label>
                        <input type="password" />
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>
        </>
    );
}

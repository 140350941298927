import React, { useState, useEffect } from 'react';

export default function Settings() {
    const [currentLanguage, setCurrentLanguage] = useState('hun');
    const [isNewsletter, setIsNewsletter] = useState(true);
    const [isEmailNotification, setIsEmailNotification] = useState(true);

    const languageCodes = { hun: 'Magyar', eng: 'Angol' };

    useEffect(() => {
        const fetchSettings = async (id) => {
            try {
                const response = await fetch(`http://localhost:8000/admin/account-settings/${id}`);
                const settingsData = await response.json();
                const settings = settingsData.data[0];

                setCurrentLanguage(settings.account_language);
                setIsNewsletter(settings.newsletter);
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
        };

        fetchSettings(1);
    }, []);

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
        // Save e.target.value to database
    };

    const handleNewsletterChange = (e) => {
        setIsNewsletter(!isNewsletter);
        // Save !isNewsletter to database
    };

    const handleEmailNotificationChange = (e) => {
        setIsEmailNotification(!isEmailNotification);
        // Save !isNewsletter to database
    };

    return (
        <>
            <div className='breadcrumbs'>Profil / Beállítások</div>

            <section className="card ">
                <h2>Fiók nyelve</h2>

                <form action="">

                    <div className="form-group">
                        <label>Nyelv</label>
                        <select value={currentLanguage} onChange={handleLanguageChange}>
                            {Object.entries(languageCodes).map(([code, label]) => (
                                <option key={code} value={code}>{label}</option>
                            ))}
                        </select>
                    </div>

                    <button className="btn save-btn">Mentés</button>
                </form>
            </section>

            <section className="card ">
                <h2>Értesítések</h2>

                <div className="notification-settings">
                    <label>Email értesítések</label>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={isEmailNotification} onChange={handleEmailNotificationChange} />
                        <span className="slider"></span>
                    </label>
                </div>

                <div className="notification-settings">
                    <label>Hírlevél feliratkozás</label>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={isNewsletter} onChange={handleNewsletterChange} />
                        <span className="slider"></span>
                    </label>
                </div>
            </section>
        </>
    );
}

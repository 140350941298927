const subscribers = [
    { isActive: true, name: "John Doe", email: "john@example.com", groups: ["BEMER", "Safe Laser"] },
    { isActive: true, name: "Jane Smith", email: "jane@example.com", groups: ["BEMER", "Safe Laser", "SPA Kezelések"] },
    { isActive: true, name: "Alice Brown", email: "alice@example.com", groups: ["BEMER", "Safe Laser", "SPA Kezelések", "Masszázsok"] },
    { isActive: true, name: "Michael Johnson", email: "michael@example.com", groups: ["BEMER", "Masszázsok"] },
    { isActive: true, name: "Emily Davis", email: "emily@example.com", groups: ["Safe Laser", "SPA Kezelések"] },
    { isActive: true, name: "-", email: "david@example.com", groups: ["BEMER", "Masszázsok", "SPA Kezelések"] },
    { isActive: true, name: "Sophia Martin", email: "sophia@example.com", groups: ["BEMER", "Safe Laser"] },
    { isActive: true, name: "Daniel White", email: "daniel@example.com", groups: ["SPA Kezelések", "Masszázsok"] },
    { isActive: true, name: "Olivia Thompson", email: "olivia@example.com", groups: ["BEMER", "SPA Kezelések", "Masszázsok"] },
    { isActive: true, name: "James Garcia", email: "james@example.com", groups: ["Safe Laser", "SPA Kezelések", "Masszázsok"] }
];

export default subscribers;

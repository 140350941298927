import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import UserSettings from './UserSettings';
import UserBillingInfo from './UserBillingInfo';
import UserSidebar from './UserSidebar';

export default function UserDashboard() {

    const [activeTab, setActiveTab] = useState('profile');

    const tabs = [
        { name: "profile", content: <UserProfile /> },
        { name: "settings", content: <UserSettings /> },
        { name: "UserBillingInfo", content: <UserBillingInfo /> },
    ];


    return (
        <div className="dashboard">

            <UserSidebar setActiveTab={setActiveTab} />

            <main className="content">
                <header>
                    <h1>Üdvözöljük Károly!</h1>
                    <Link to="/bejelentkezes" className="btn logout-btn">Kijelentkezés</Link>
                </header>

                {tabs.find(tab => tab.name === activeTab).content}
            </main>
        </div>
    );
}

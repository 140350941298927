import React from 'react';

export default function UserBillingInfo() {
    return (
        <section className="card ">
            <h2>Számlázási adatok</h2>

            <form action="">
                <div className="form-group">
                    <label>Számlázási név</label>
                    <span>FKF Zrt.</span>
                </div>
                <div className="form-group">
                    <label>Számlázási cím</label>
                    <span>1068 Budapest, Rippl-Rónai utca 34-36.</span>
                </div>
                <div className="form-group">
                    <label>Új számlázási név *</label>
                    <input type="text" />
                </div>
                <div className="form-group">
                    <label>Új számlázási cím *</label>
                    <input type="text" />
                </div>

                <button className="btn save-btn">Mentés</button>
            </form>
        </section>
    );
}

const admin = {
    adataim: {
        azonosito: 't495k',
        nev: 'Kárpáti Anett',
        email: 'talpak@talpak.hu',
        tel: '+36 20 312 9332',
        szamla_nev: 'Kárpáti Anett ev.',
        szamla_cim: '2370 Dabas, Bem utca 53.',
        password: 'aaaa'
    },
    beallitasok: {
        nyelv: 'magyar',
        email_ertesit: true,
        hirlevel: true
    }
};

export default admin;